import { getAuth } from 'firebase/auth';
import { getDatabase, ref } from 'firebase/database';
import { getStorage } from "firebase/storage";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import "firebase/storage"


const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const database = getDatabase(firebaseApp);
export const storage = getStorage(firebaseApp);

// CONFIG - REMIGIO - PB
// export const cidade = 'Remígio - PB'
// export const KEY_REF = 'db/cidades/25127051'
// export const KEY_LINK = 'https://camaraderemigio.pb.gov.br/'
// export const camara = true;

// CONFIG - REMIGIO - PB
export const cidade = 'Gurjão - PB'
export const KEY_REF = 'db/cidades/25065091'
export const KEY_LINK = 'https://camaradegurjao.pb.gov.br/'
export const camara = true;


export default firebaseApp;