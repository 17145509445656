import React, { useState, useEffect } from 'react';
import './pages_documentos.css';
import { getDatabase, ref, get } from 'firebase/database';
import firebaseApp, { KEY_REF } from '../adm/admin/firebaseConfig';
import { Box } from '@mui/material';
import TitleSection from '../utils/TittleTheme';
import DataTable from '../utils/Tabela';


export default function Requerimentos() {

	const [newsData, setNewsData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const databaseInstance = getDatabase(firebaseApp);
				const noticiasRef = ref(databaseInstance, `${KEY_REF}/requerimentos`);
	
				const snapshot = await get(noticiasRef);
				const data = snapshot.val();
	
				if (data) {
					const newsArray = Object.keys(data).map((key) => ({
						id: key,
						title: data[key].nome,
						mes: data[key].mes,
						ano: data[key].ano,
						href: data[key].href,
					}));
					setNewsData(newsArray);
				}
			} catch (error) {
				console.error('Erro ao carregar notícias:', error);
			}
		};
	
		fetchData();
	}, []);
	



	return (
		<Box sx={{ padding: 3, maxWidth: '1200px', mx: 'auto' }}>


			<TitleSection title={'Requerimentos'} subtitle={'Solicitações e Propostas Oficiais para o Município'}/>

			<DataTable data={newsData} />
		</Box>
	)
}

