import React, { useEffect, useState } from "react";
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography, Grid, Container } from "@mui/material";
import firebaseApp, { KEY_REF } from "../adm/admin/firebaseConfig";
import { getDatabase, ref, get } from "firebase/database";
import TitleSection from "../utils/TittleTheme";

const Vereadores = () => {
  const [vereadores, setVereadores] = useState([]);

  useEffect(() => {
    const fetchVereadores = async () => {
      try {
        const databaseInstance = getDatabase(firebaseApp);
        const vereadoresRef = ref(databaseInstance, `${KEY_REF}/vereadores`);
        const snapshot = await get(vereadoresRef);

        const data = snapshot.val();

        if (data) {
          const vereadoresArray = Object.keys(data).map((key) => ({
            id: key,
            nome: data[key].nome,
            partido: data[key].partido,
            imagem: data[key].documentoUrl,
          }));

          setVereadores(vereadoresArray);
        }
      } catch (error) {
        console.error('Error fetching vereadores data:', error);
      }
    };

    fetchVereadores();
  }, []);

  const renderVereadores = (vereador) => {
    return (
      <Grid item xs={12} sm={6} md={4} lg={3} key={vereador.id}>
        <Card sx={{ "&:hover": { boxShadow: 4 }, height: "100%" }}>
          <CardActionArea href={`/vereadores/${vereador.id}`}>
            <CardMedia
              component="img"
              height="280"
              image={vereador.imagem}
              alt={vereador.nome}
              sx={{ objectFit: "cover", borderRadius: "4px 4px 0 0" }}
            />
            <CardContent sx={{ textAlign: "center", padding: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "1rem" }}>
                {vereador.nome}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: "0.85rem", color: "text.secondary" }}>
                {vereador.partido}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );
  };

  return (
    <Box sx={{ marginTop: 3 }}>
      <TitleSection
        title="Nossos Vereadores"
        subtitle="Conheça os representantes que trabalham por você, conectando a comunidade às soluções e promovendo transparência no serviço público."
      />
      <Container maxWidth="lg" sx={{ paddingX: { xs: 2, md: 2 }, marginY: 3 }}>
        <Grid container spacing={2}>
          {vereadores.map(renderVereadores)}
        </Grid>
      </Container>
    </Box>
  );
};

export default Vereadores;
