import React, { useEffect } from 'react';
import { Container, Typography, Box, Paper, Button } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'; // Ícone para o botão E-SIC
import VerifiedIcon from '@mui/icons-material/Verified'; // Ícone para o botão de transparência fiscal
import TitleSection from '../utils/TittleTheme';
import SidebarNavigation from '../utils/SideBarNavigation';

const LgpdInfoPage = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3, py: 5 }}>
            {/* Main Content Area */}
            <Box sx={{ flex: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
                {/* Title Section */}
                <Box sx={{ width: "100%" }}>
                    <TitleSection 
                        title="Política de Privacidade e LGPD"
                        subtitle="Saiba como protegemos suas informações e mantemos a sua privacidade"
                    />
                </Box>

                {/* LGPD Information Section */}
                <Paper elevation={3} sx={{ p: 3, mb: 3, borderRadius: 2 }}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>1. Coleta e Segurança de Dados</Typography>
                    <Typography variant="body1" paragraph sx={{ color: '#666', textAlign: 'justify' }}>
                        Coletamos informações essenciais para personalizar e melhorar sua experiência em nosso site. Dados como nome, email e endereço IP são coletados de forma segura e são usados exclusivamente para fins de contato e atendimento a solicitações. Valorizamos sua privacidade e garantimos que suas informações estão bem protegidas por tecnologias de segurança robustas.
                    </Typography>

                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>2. Uso de Cookies</Typography>
                    <Typography variant="body1" paragraph sx={{ color: '#666', textAlign: 'justify' }}>
                        Utilizamos cookies para oferecer uma experiência de navegação mais fluida e personalizada. Esses cookies permitem que nossas páginas carreguem com maior rapidez e memorizem suas preferências. Você pode optar por desativar os cookies nas configurações do seu navegador, mas isso pode impactar algumas funcionalidades do nosso site.
                    </Typography>

                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>3. Coleta de Dados em Páginas Específicas</Typography>
                    <Typography variant="body1" paragraph sx={{ color: '#666', textAlign: 'justify' }}>
                        Dados adicionais são coletados em páginas de serviços como o E-SIC (Sistema de Informação ao Cidadão), Carta de Serviços e Ouvidoria. Essas informações permitem o acompanhamento de suas solicitações e o atendimento personalizado. Coletamos esses dados apenas para a gestão eficiente das manifestações dos cidadãos e em conformidade com a legislação vigente.
                    </Typography>

                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>4. Segurança de Banco de Dados e Autenticação</Typography>
                    <Typography variant="body1" paragraph sx={{ color: '#666', textAlign: 'justify' }}>
                        Nossa aplicação utiliza um banco de dados seguro para armazenar todas as informações de forma criptografada e com acesso restrito. Para a alteração de dados, aplicamos autenticação segura via Google, garantindo que apenas usuários autorizados possam realizar alterações. Além disso, toda a comunicação com nosso site é protegida por um certificado SSL, que oferece uma camada extra de segurança contra acessos não autorizados.
                    </Typography>

                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>5. Serviços de Terceiros</Typography>
                    <Typography variant="body1" paragraph sx={{ color: '#666', textAlign: 'justify' }}>
                        O gerenciamento de serviços de contracheque, ficha funcional e folha de pagamento é realizado pela empresa Fran Informática, que é responsável pela segurança e privacidade dos dados relacionados a esses serviços. Não nos responsabilizamos pelo manuseio de dados fornecidos diretamente a terceiros, pois são gerenciados exclusivamente pela Fran Informática.
                    </Typography>

                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>6. Seus Direitos e Acesso aos Dados</Typography>
                    <Typography variant="body1" paragraph sx={{ color: '#666', textAlign: 'justify' }}>
                        Em conformidade com a LGPD, você tem o direito de solicitar acesso, correção ou exclusão dos seus dados pessoais. Essas solicitações podem ser feitas através do Sistema E-SIC, que oferece um canal seguro para realizar sua requisição. Clique no botão abaixo para acessar o E-SIC e enviar sua solicitação.
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'left', mt: 2 }}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            href="/e-sic" 
                            sx={{ 
                                textTransform: 'none', 
                                fontWeight: 'bold', 
                                borderRadius: 2, 
                                px: 2, 
                                py: 1, 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 1,
                                color: '#FFF',
                                '&:hover': {
                                    backgroundColor: '#004d40',
                                    transform: 'scale(1.02)',
                                    color: '#FFF'
                                }
                            }}
                        >
                            <InfoOutlinedIcon sx={{ fontSize: 20 }} />
                            Acessar E-SIC
                        </Button>
                    </Box>

                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333', mt: 3 }}>7. Transparência Fiscal</Typography>
                    <Typography variant="body1" paragraph sx={{ color: '#666', textAlign: 'justify' }}>
                        Nosso site segue as normas de transparência fiscal com a maior conformidade. Recebemos nota máxima de transparência fiscal e estamos 100% de acordo com o Tribunal de Contas do Estado da Paraíba (TCE-PB). Esta conformidade assegura que nossos processos são transparentes e atendem aos critérios exigidos.
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'left', mt: 2 }}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            href="https://www.gurjao.pb.gov.br/documentos/turmalina-tce-pb.pdf" 
                            target="_blank" 
                            sx={{ 
                                textTransform: 'none', 
                                fontWeight: 'bold', 
                                borderRadius: 2, 
                                px: 2, 
                                py: 1, 
                                display: 'flex', 
                                alignItems: 'center', 
                                gap: 1,
                                color: '#FFF',
                                '&:hover': {
                                    backgroundColor: '#004d40',
                                    transform: 'scale(1.02)',
                                    color: '#FFF'
                                }
                            }}
                        >
                            <VerifiedIcon sx={{ fontSize: 20 }} />
                            Comprovação de Transparência
                        </Button>
                    </Box>
                </Paper>
            </Box>

            <Box sx={{ flex: { xs: 1, md: 1.5 }, minWidth: 250 }} marginTop={0}>
                <SidebarNavigation />
            </Box>
        </Container>
    );
};

export default LgpdInfoPage;
