import React, { useEffect, useState } from 'react';
import {
  Box, TextField, Button, Grid, Typography, InputAdornment, MenuItem, Card, CardContent, CardMedia, useMediaQuery, Pagination
} from '@mui/material';
import { format, parseISO } from 'date-fns';
import SearchIcon from '@mui/icons-material/Search';
import { getDatabase, ref, get, query, orderByChild } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';
import { useTheme } from '@mui/material/styles';
import TitleSection from './utils/TittleTheme';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

function removePTags(html) {
  return html ? html.replace(/<p>/g, '').replace(/<\/p>/g, '') : '';
}

export default function TodasNoticias() {
  const [noticiasRender, setNoticiasRender] = useState([]);
  const [filteredNoticias, setFilteredNoticias] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [years, setYears] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const itemsPerPage = 9;

  const months = [
    { value: '', label: 'Todos' },
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' },
  ];

  useEffect(() => {
    const fetchNoticias = async () => {
      try {
        const databaseInstance = getDatabase(firebaseApp);
        const noticiasRef = query(ref(databaseInstance, `${KEY_REF}/noticias`), orderByChild('data'));
        const snapshot = await get(noticiasRef);
        
        if (snapshot.exists()) {
          const data = snapshot.val();
          const noticiasArray = Object.keys(data).map((key) => {
            const formattedDate = format(parseISO(data[key].data), 'dd/MM/yyyy');
            return {
              id: key,
              data: formattedDate,
              titulo: data[key].titulo,
              descricao: data[key].descricao,
              imagem: data[key].imagem,
            };
          });
  
          const noticiasOrdenadas = noticiasArray.reverse();
          const uniqueYears = [...new Set(noticiasOrdenadas.map((noticia) => noticia.data.slice(6, 10)))];
          
          setYears(uniqueYears);
          setNoticiasRender(noticiasOrdenadas);
          setFilteredNoticias(noticiasOrdenadas);
        }
      } catch (error) {
        console.error("Erro ao buscar notícias:", error);
      }
    };
  
    fetchNoticias();
  }, []);
  


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleSearch = () => {
    const filtered = noticiasRender.filter((noticia) => {
      const matchesTitleOrDescription = noticia.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        noticia.descricao.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesMonth = selectedMonth ? noticia.data.slice(3, 5) === selectedMonth : true;
      const matchesYear = selectedYear ? noticia.data.slice(6, 10) === selectedYear : true;
      return matchesTitleOrDescription && matchesMonth && matchesYear;
    });
    setFilteredNoticias(filtered);
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const renderNoticias = (noticia) => {
    const truncatedDescricao = noticia.descricao.length > 80
      ? noticia.descricao.slice(0, 80) + "..."
      : noticia.descricao;

    const tituloParaURL = noticia.titulo.replace(/\s+/g, '-').toLowerCase();
    const descricaoSemPTags = removePTags(truncatedDescricao);

    return (
      <Grid item xs={12} md={6} lg={4} key={noticia.id}>
         <Card sx={{ boxShadow: 4, borderRadius: 1, overflow: 'hidden', transition: '0.4s', '&:hover': { boxShadow: 8, transform: 'scale(1.01)' }, maxWidth: 500, margin: 'auto' }}>
                    <CardMedia
                        component="img"
                        height="200"
                        image={noticia.imagem}
                        alt={noticia.titulo}
                        sx={{ objectFit: 'cover' }}
                    />
                    <CardContent sx={{ backgroundColor: '#fafafa', p: 3 }}>
                        <Typography variant="subtitle2" color="primary" sx={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: 15 }}>
                            Notícias
                        </Typography>
                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {noticia.titulo}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic', mb: 1 }}>
                            Publicado em {noticia.data}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, color: '#333', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
                            {descricaoSemPTags}
                        </Typography>
                        <Link to={`/noticias/${tituloParaURL}/${noticia.id}`}>
                            <Button
                                variant="contained"
                                size="small"
                                startIcon={<OpenInNewIcon />}
                                sx={{ mt: 2, textTransform: 'uppercase', fontWeight: 'bold', color: '#ffffff', backgroundColor: '#3296DE', '&:hover': { backgroundColor: '#1d1d1d' } }}
                            >
                                Continuar Lendo
                            </Button>
                        </Link>
                    </CardContent>
                </Card>

     
      </Grid>
    );
  };

  const renderUltimasNoticias = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = filteredNoticias.slice(startIndex, startIndex + itemsPerPage);
    return currentItems.map(renderNoticias);
  };

  return (
    <Box sx={{ p: 2, mx: 'auto', maxWidth: '1200px' }}>
      <TitleSection title={'TODAS AS NOTÍCIAS'}/>

      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          gap: 2,
          mb: 3, mt: 3,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <TextField
          label="Pesquisar"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: isMobile ? '100%' : '250px' }}
        />
        <TextField
          select
          label="Mês"
          value={selectedMonth}
          onChange={(e) => setSelectedMonth(e.target.value)}
          variant="outlined"
          sx={{ width: isMobile ? '100%' : '150px' }}
        >
          {months.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Ano"
          value={selectedYear}
          onChange={(e) => setSelectedYear(e.target.value)}
          variant="outlined"
          sx={{ width: isMobile ? '100%' : '150px' }}
        >
          <MenuItem value="">Todos</MenuItem>
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </TextField>
        <Button variant="contained" onClick={handleSearch} sx={{ width: isMobile ? '100%' : 'auto' }}>
          Aplicar Filtros
        </Button>
      </Box>

      <Grid container spacing={3}>
        {renderUltimasNoticias()}
      </Grid>

      <Box display="flex" justifyContent="center" mt={3}>
        <Pagination
          count={Math.ceil(filteredNoticias.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
}
