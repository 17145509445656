import React, { useState, useEffect } from 'react';
import { LinearProgress, Box, Typography, Container, Grid } from '@mui/material';
import TitleSection from '../utils/TittleTheme';

export default function IPTU({config}) {
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setLoadingProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          setIframeLoaded(true);
          return 100;
        }
        return Math.min(oldProgress + 10, 100);
      });
    }, 350);

    return () => clearInterval(timer);
  }, []);

  return (
    <Container component="main" maxWidth="lg">
      <Box mt={4} mb={4}>

        <TitleSection
          title="IPTU - Imposto Predial e Territorial Urbano"
          subtitle="Emissão de boleto de 2 via Pagamento do IPTU."
        />

        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '600px' }}>
          {!iframeLoaded && (
            <Box width="100%" textAlign="center">
              <Typography variant="h6" mb={1}>
                Carregando conteúdo, por favor aguarde...
              </Typography>
              <LinearProgress variant="determinate" value={loadingProgress} />
              <Typography variant="body2" color="textSecondary" mt={1}>
                {loadingProgress}%
              </Typography>
            </Box>
          )}

          <iframe
            src={config.links.iptu}
            title="IPTU"
            style={{
              border: '0px solid #ccc',
              width: '100%',
              height: '600px',
              display: iframeLoaded ? 'block' : 'none'
            }}
          ></iframe>
        </Grid>

      
      </Box>
    </Container>
  );
}
