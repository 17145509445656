import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Box, Container, Grid, Card, CardContent, CardMedia, CardActions, IconButton, Snackbar, Divider, Tooltip, useMediaQuery, List, ListItem, ListItemIcon, ListItemText, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ThumbUp, ThumbDown, Visibility, WhatsApp, Instagram, FileDownload, LocationOn, Info, AccessTime, Person, CheckCircle, ExpandMore } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { getDatabase, ref, get } from 'firebase/database';
import firebaseApp, { KEY_REF } from './adm/admin/firebaseConfig';
import TitleSection from './utils/TittleTheme';
import TableListFirebase from './utils/TableListFirebase';
import { Assignment, CreditCard, School, Business } from '@mui/icons-material';
import LayoutBanner from './LayoutBanner';

export default function SalaEmpreendedor() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [noticias, setNoticias] = useState([]);
  const [likes, setLikes] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const lightBlue = '#4C96FF'

  useEffect(() => {
    const fetchData = async () => {
      const databaseInstance = getDatabase(firebaseApp);
      const noticiasRef = ref(databaseInstance, `${KEY_REF}/saladoempreendedor`);
  
      try {
        const snapshot = await get(noticiasRef);
        const data = snapshot.val();
  
        if (data) {
          const avisosArray = Object.keys(data).map((key) => ({
            id: key,
            ...data[key],
          }));
          const avisosOrdenados = avisosArray.filter(aviso => aviso.ativo).sort((a, b) => new Date(b.date) - new Date(a.date));
          setNoticias(avisosOrdenados);
        }
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      }
  
      const savedLikes = JSON.parse(localStorage.getItem('likes')) || {};
      setLikes(savedLikes);
    };
  
    fetchData();
  }, []);
  
  

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const handleLike = (id) => {
    const newLikes = { ...likes, [id]: likes[id] === 'like' ? null : 'like' };
    setLikes(newLikes);
    localStorage.setItem('likes', JSON.stringify(newLikes));
    setSnackbarMessage('Gostei salvo!');
    setSnackbarOpen(true);
  };

  const handleDislike = (id) => {
    const newLikes = { ...likes, [id]: likes[id] === 'dislike' ? null : 'dislike' };
    setLikes(newLikes);
    localStorage.setItem('likes', JSON.stringify(newLikes));
    setSnackbarMessage('Não Gostei salvo!');
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <AppBar position="static" color="primary">
        <Toolbar
          sx={{
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: isMobile ? 'center' : 'space-between',
          }}
        >
          <Box display="flex" justifyContent="center" flexGrow={1} sx={{padding: 1.4}}>
            <img
              src="/imagens/sala_day.png"
              alt="Sala do Empreendedor"
              style={{ width: isMobile ? '150px' : '200px', marginBottom: isMobile ? '10px' : 0 }}
            />
          </Box>

          <Box display="flex" justifyContent={isMobile ? 'center' : 'flex-end'} width={isMobile ? '100%' : 'auto'}>
            <Tooltip title="WhatsApp">
              <IconButton color="inherit" href="https://api.whatsapp.com/send?phone=558387367266">
                <WhatsApp />
              </IconButton>
            </Tooltip>
            <Tooltip title="Instagram">
              <IconButton color="inherit" href="https://www.instagram.com/saladoempreendedorgurjao">
                <Instagram />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>

    
      <Container sx={{ marginTop: 5 }}>
        <Box sx={{ width: "100%" }}>
          <TitleSection title={'INFORMAÇÕES'} subtitle={'Bem-vindo(a) à Sala do Empreendedor'} />
        </Box>
        <Card variant="outlined" sx={{ borderRadius: '10px', p: 2, mb: 4 }}>
          <CardContent>
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'} justifyContent="space-between">
              <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', alignItems: 'center', mb: isMobile ? 1 : 0 }}>
                <LocationOn sx={{ mr: 1, color: 'primary.main' }} /> R. Vicente Borges Gurjão, Gurjão - PB, 58670-000
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', alignItems: 'center', mb: isMobile ? 1 : 0 }}>
                <AccessTime sx={{ mr: 1, color: 'primary.main' }} /> 08h às 14h (Segunda a Sexta)
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                <Person sx={{ mr: 1, color: 'primary.main' }} /> Agente: Lindon Johnson Nascimento
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Container>


      <Container sx={{ marginTop: 3 }}>
        <Box sx={{ width: "100%" }}>
          <TitleSection title={'SOBRE A SALA DO EMPREENDEDOR'} novo={true} subtitle={'Conheça mais sobre nossos serviços e benefícios'} share={true} />
        </Box>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography sx={{ color: '#1D1D1D', fontSize: 14 }} fontWeight="bold">SERVIÇOS OFERECIDOS</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {[
                "Simplificação e desburocratização do processo de registro e licenciamento de empresas",
                "Facilidade para abertura, regularização e baixa de empresas",
                "Serviços exclusivos para Microempreendedores Individuais (MEI)",
                "Suporte técnico e capacitações",
                "Orientação sobre abertura de empresas",
                "Consultoria financeira",
                "Acesso a linhas de crédito",
              ].map((item, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel2a-content" id="panel2a-header">
            <Typography sx={{ color: '#1D1D1D', fontSize: 14 }} fontWeight="bold">PARCERIA COM A SEBRAE</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" color="textSecondary">
              A Sala do Empreendedor é resultado de uma parceria entre o SEBRAE e as Prefeituras Municipais, de acordo com a Lei Complementar 123/2006.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography sx={{ color: '#1D1D1D', fontSize: 14 }} fontWeight="bold">IMPORTÂNCIA PARA O MUNICÍPIO</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List>
              {[
                "Impulsiona a economia",
                "Gera empregos",
                "Aumenta a arrecadação",
                "Promove o empreendedorismo local",
                "Contribui para a criação de um ambiente de negócios mais estável",
              ].map((benefit, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={benefit} />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      </Container>


      <Container sx={{ marginTop: 3 }}>
        <TableListFirebase
          title="AVISOS IMPORTANTES"
          subtitle={'Comunicados importantes feitos pela sala do Empreendedor'}
          link="/avisos"
          data={noticias}
        />
      </Container>

      <Container sx={{ marginTop: 3 }}>
        <Box sx={{ width: "100%" }}>
          <TitleSection title={'ACESSO RÁPIDO'} subtitle={'Links importantes disponibilizados'} share={true} novo={true} />
        </Box>
        <Grid container spacing={2} justifyContent="center">
          {[
            { link: "https://www.gov.br/empresas-e-negocios/pt-br/empreendedor/quero-ser-mei/atividades-permitidas", icon: <Assignment sx={{ fontSize: 60, color: lightBlue }} />, text: "Atividades MEI" },
            { link: "https://www.gov.br/empresas-e-negocios/pt-br/empreendedor/quero-ser-mei", icon: <Business sx={{ fontSize: 60, color: lightBlue }} />, text: "Formalize-se" },
            { link: "https://api.whatsapp.com/send?phone=558387367266", icon: <WhatsApp sx={{ fontSize: 60, color: lightBlue }} />, text: "Emissão de alvará" },
            { link: "https://www8.receita.fazenda.gov.br/simplesnacional/aplicacoes/atspo/pgmei.app/identificacao", icon: <CreditCard sx={{ fontSize: 60, color: lightBlue }} />, text: "Emitir DAS" },
            { link: "https://www.nfse.gov.br/EmissorNacional/Login?ReturnUrl=%2fEmissorNacional", icon: <FileDownload sx={{ fontSize: 60, color: lightBlue }} />, text: "Notas Fiscais MEI" },
            { link: "https://enfse.elmartecnologia.com.br/201084", icon: <FileDownload sx={{ fontSize: 60, color: lightBlue }} />, text: "Emissor de NF Municipal" },
            { link: "/saladoempreendedor/PLANO DE CONTRATAÇÕES ANUAL - GURJÃO PB.pdf", icon: <FileDownload sx={{ fontSize: 60, color: lightBlue }} />, text: "Licitações 2024" },
            { link: "https://www.sebrae.com.br/sites/PortalSebrae/cursosonline", icon: <School sx={{ fontSize: 60, color: lightBlue }} />, text: "Cursos Online SEBRAE" },
            { link: "https://www.bndes.gov.br/wps/portal/site/home/financiamento", icon: <CreditCard sx={{ fontSize: 60, color: lightBlue }} />, text: "Linhas de Crédito" },
            { link: "https://www.gov.br/esocial/pt-br/esocial-mei", icon: <Assignment sx={{ fontSize: 60, color: lightBlue }} />, text: "eSocial para MEI" },
          ].map((service, index) => (
            <Grid item xs={12} sm={6} md={2.4} key={index}>
              <a href={service.link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Card variant="outlined" sx={{
                  textAlign: 'center',
                  borderRadius: '8px',
                  padding: 2,
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                  },
                }}>
                  {service.icon}
                  <Typography variant="subtitle2" color="textPrimary" sx={{ fontWeight: 500, mt: 1 }}>
                    {service.text}
                  </Typography>
                </Card>
              </a>
            </Grid>
          ))}
        </Grid>
      </Container>

      <LayoutBanner link={'https://www.gurjao.pb.gov.br/noticias/pr%C3%AAmio-de-excel%C3%AAncia:-sala-do-empreendedor-de-gurj%C3%A3o-recebe-selo-ouro-do-sebrae/-NjtEpWV5xQCH4R0FwkJ'} src={'/imagens/saladoempreendedor.jpg'} />

      <Container sx={{ marginTop: 3, marginBottom: 5 }}>
        <Box sx={{ width: "100%" }}>
          <TitleSection title={'VÍDEOS DISPONÍVEIS'} subtitle={'A sala do Empreendedor pensou em cada detalhe para você!'} novo={true} />
        </Box>
        <Grid container spacing={2}>
          {[
            { id: 1, title: 'Direitos e Deveres do MEI', url: 'https://www.youtube.com/embed/TgjNNA7wy14', duration: '7 min 23 seg', description: 'Quais são os direitos e deveres de quem é MEI?' },
            { id: 2, title: 'Emissão de Nota Fiscal MEI', url: 'https://www.youtube.com/embed/0JVqmJFAhAg', duration: '6 min 25 seg', description: 'Aprenda a emitir nota fiscal de prestação de serviços.' },
            { id: 3, title: 'MEI pode ser CLT?', url: 'https://www.youtube.com/embed/AtAqx9LEfho', duration: '3 min 14 seg', description: 'Descubra os direitos e benefícios ao ser MEI e CLT.' },
            { id: 4, title: 'O que acontece se não pagar o MEI?', url: 'https://www.youtube.com/embed/EBX85oRUjaM', duration: '7 min 15 seg', description: 'Assista para descobrir os riscos e regularizar a situação!' },
            { id: 5, title: 'Empréstimos para MEI', url: 'https://www.youtube.com/embed/On4Ge4BRuhw', duration: '7 min 24 seg', description: 'Descubra como solicitar empréstimo como MEI.' },
            { id: 6, title: 'MEI precisa de Alvará?', url: 'https://www.youtube.com/embed/f2vFZaiUeDY', duration: '6 min 30 seg', description: 'Aprenda sobre a necessidade de alvará para MEI.' },
            { id: 7, title: 'Gurjão Plus App', url: 'https://www.youtube.com/embed/Xw0vsmEfcxU', duration: '2 min 33 seg', description: 'Conheça o aplicativo Gurjão Plus para comerciantes locais.' },
          ].map(video => (
            <Grid item xs={12} sm={6} md={4} key={video.id}>
              <Card sx={{ borderRadius: '10px' }}>
                <CardMedia component="iframe" height="200" image={video.url} title={video.title} />
                <CardContent>
                  <Typography variant="subtitle1" component="div">
                    {video.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {video.description}
                  </Typography>
                  <Typography variant="caption" color="textSecondary" display="block">
                    {video.duration}
                  </Typography>
                </CardContent>
                <CardActions>
                  <IconButton onClick={() => handleLike(video.id)} color={likes[video.id] === 'like' ? 'primary' : 'default'}>
                    <ThumbUp />
                  </IconButton>
                  <IconButton onClick={() => handleDislike(video.id)} color={likes[video.id] === 'dislike' ? 'error' : 'default'}>
                    <ThumbDown />
                  </IconButton>
                  <IconButton disabled>
                    <Visibility />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </>
  );
}
