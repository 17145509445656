
export const items = [
    {
        category: "Festas Tradicionais",
        events: [
            {
                name: "Bode na Rua",
                description: "Expofeira de caprinos e ovinos, com atividades culturais, concursos e gastronomia típica.",
                imageUrl: "/imagens/pontos/-NQgFiE8kFyRfPt6Guib.JPEG",
                address: "Praça Pública, Gurjão - PB",
                openingHours: "Final de Julho"
            },
            {
                name: "São João da Gente",
                description: "Festival junino com quadrilhas, forró e atrações regionais em junho.",
                imageUrl: "/imagens/pontos/-NQgY7680mhtFErGP6db.JPEG",
                address: "Praça Pública, Gurjão - PB",
                openingHours: "Final de Junho"
            },
            {
                name: "Festa de São Sebastião",
                description: "Celebração religiosa em janeiro com procissões e festividades na Paróquia de São Sebastião.",
                imageUrl: "/imagens/pontos/-NQgZDatD0ik_GtLKOEE.JPEG",
                address: "Paróquia de São Sebastião, Praça pública",
                openingHours: "Começo de Janeiro"
            },
        ]
    },
    {
        category: "Pontos Turísticos",
        events: [
            {
                name: "Pedra da Tartaruga",
                description: "Formação rochosa única em forma de tartaruga, localizada no Sítio Santa Rita.",
                imageUrl: "/imagens/pontos/-NQgPBOIcFSVWuW3WYHO.JPEG",
                address: "Sítio Santa Rita, Zona Rural",
                openingHours: "07:00 - 17:00"
            },
            {
                name: "Praia Deserta",
                description: "Geossítio com inscrições rupestres de antigos povos indígenas.",
                imageUrl: "/imagens/pontos/-NQgQ9pzJUDysGVzsGGb.JPEG",
                address: "Sítio Caatinga, Zona rural, Gurjão - Pb",
                openingHours: "08:00 - 17:00"
            },
        ]
    },
    {
        category: "Pontos de Interesse",
        events: [
            {
                name: "Sala do Empreendedor",
                description: "Apoio e capacitação para microempreendedores, com foco na formalização de negócios.",
                imageUrl: "/imagens/pontos/-NQVSo-5WXtYvVJ7unQ0.JPEG",
                address: "R. Vicente Borges Gurjão, N°158, centro",
                openingHours: "08:00 - 14:00"
            },
            {
                name: "Paróquia de São Sebastião",
                description: "Igreja histórica, conhecida como “Catedral do Cariri”.",
                imageUrl: "/imagens/pontos/-NQgF1PQSJRTgFcI4QXE.JPEG",
                address: "R. Carlos Vidal, 36, Centro, Gurjão - PB",
                openingHours: "07:00 - 21:00"
            },
            {
                name: "Prefeitura Municipal de Gurjão",
                description: "Centro administrativo",
                imageUrl: "/imagens/pontos/-NQgE2qXbdMhKmltkrG6.JPEG",
                address: "R. Vicente Borges Gurjão, N° 158, centro",
                openingHours: "08:00 - 14:00"
            },
        ]
    }

];